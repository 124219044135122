import React from 'react';
import styles from '../styles/home.module.scss';
import 'antd/dist/antd.css';
import {Menu, Layout, Popover, Avatar, Switch, Button, Modal, message} from 'antd'
import BaseComponent from "../components/BaseComponent"
import LoginRegisterModal from "./modal/LoginRegisterModal"
import PaymentModal from "./modal/PaymentModal"
import CreditCardModal from "./modal/CreditCardModal"
import StorageUtils from "../tools/StorageUtils";
import GiftCardModal from "./modal/GiftCardModal";
import InvitationModal from "./modal/InvitationModal";
import CryptoCoinModal from "./modal/CryptoCoinModal";
import BgAnimations from "./ui/BgAnimations";
import UserInfoModal from "./modal/UserInfoModal"
import ResetPasswordModal from "./modal/ResetPasswordModal"
import CommonUtils from "../tools/CommonUtils"
import WebServerRestClient from "../tools/WebServerRestClient"
import ChangeEmailModal from "./modal/ChangeEmailModal";

const { Header, Footer, Content } = Layout;

export default class Home extends BaseComponent {


    constructor(props) {
        super(props);
        this.state = {
            showLoginRegisterModal: false,
            showResetPasswordModal: false,
            showChangeEmailModal: false,
            showUserInfoModal: false,
            showInvitationModal: false,
            showGiftCardModal: false,
            showPaymentModal: false,
            showCreditCardModal: false,
            showCryptoCoinModal: false,
            selectedPackage: {},
            isLogin: false,
            email: '',
            userInfo: {},
            appStoreModalVisible: false,
            linuxTooltipModalVisible: false
        }
    }

    componentDidMount() {
        this.setState({email: StorageUtils.getEmail()})
        this.loadUserInfo()
    }

    loadUserInfo(userId = StorageUtils.getUserId()) {
        setTimeout(() => {
            if (!CommonUtils.isEmpty(userId)) {
                this.setState({loading: true})
                new WebServerRestClient().GET('/user/info/' + userId)
                    .then(response => {
                        this.setState({loading: false})
                        if (response.code === 200) {
                            this.setState({email: response.data.email})
                            this.setState({userInfo: response.data})
                        } else if (response.code === 401 || response.code === 403) {
                            StorageUtils.remove(window.constants.CURRENT_USER);
                            this.setState({email: '', isLogin: false})
                        } else {
                            message.warn(response['msg'])
                        }
                    }).catch(err => {
                    this.setState({loading: false})
                    message.error("Error: " + err.message)
                })
            }
        }, 1000)    // delay 1 second to wait for token save to local storage
    }

    scrollToAnchor = (id) => {
        document.getElementById(id).scrollIntoView({block: "start", behavior: "smooth"});
    };

    isWeChat = function () {
        const browser = {
            versions: function () {
                const u = navigator.userAgent, app = navigator.appVersion;
                return {         //移动终端浏览器版本信息
                    trident: u.indexOf('Trident') > -1, //IE内核
                    presto: u.indexOf('Presto') > -1, //opera内核
                    webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
                    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
                    iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                    iPad: u.indexOf('iPad') > -1, //是否iPad
                    webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
                };
            }(),
            language: (navigator.browserLanguage || navigator.language).toLowerCase()
        };

        const ua = navigator.userAgent.toLowerCase();//获取判断用的对象
        return (browser.versions.mobile && ua.match(/MicroMessenger/i) == "micromessenger");
    };

    downloadAndroid = function () {
        const apkURL = window.downloads.Android + '?t=' + new Date().getTime();
        window.location.href = apkURL;
    };

    openGooglePlay = function () {
        setTimeout('window.open(window.downloads.GooglePlay);', 310);
    };

    downloadWindows = function () {
        const fileURL = window.downloads.Windows+ '?t=' + new Date().getTime();
        window.location.href = fileURL;
    };

    downloadMicrosoftStore = function () {
        const fileURL = window.downloads.MicrosoftStore+ '?t=' + new Date().getTime();
        window.open(fileURL);
    };

    downloadLinux = function () {
        const fileURL = window.downloads.Linux+ '?t=' + new Date().getTime();
        window.location.href = fileURL;
    };

    downloadMac = function () {
        const fileURL = window.downloads.MacOS + '?t=' + new Date().getTime();
        window.location.href = fileURL;
    };

    openTestFlight = function () {
        setTimeout('window.open(window.downloads.TestFlight);', 310);
    };

    openAppStore = function () {
        setTimeout('window.open(window.downloads.AppStore);', 310);
    };

    openGooglePlayAndroidTV = function () {
        setTimeout('window.open(window.downloads.GooglePlayTV);', 310);
    };

    downloadAndroidTVapk = function () {
        const apkURL = window.downloads.AndroidTVapk + '?t=' + new Date().getTime();
        window.location.href = apkURL;
    };

    // Start: app store popup handlers
    showAppStoreModal = () => {
        this.setState({
            appStoreModalVisible: true,
        });
    };

    handleAppStoreModalOk = () => {
        this.setState({ appStoreModalVisible: false });
        // this.openAppStore();
    };

    handleAppStoreModalCancel = () => {
        this.setState({ appStoreModalVisible: false });
    };
    // End: app store popup handlers

    // Start: linux tooltip popup handlers
    showlinuxTooltipModal = () => {
        this.setState({
            linuxTooltipModalVisible: true,
        });
    };

    handleLinuxModalOk = () => {
        this.setState({ linuxTooltipModalVisible: false });
    };

    handleLinuxModalCancel = () => {
        this.setState({ linuxTooltipModalVisible: false });
    };
    // End: linux tooltip popup handlers


    render() {
        let isChinese = StorageUtils.getLanguage() === window.constants.LANGUAGE_CN;
        let vpnServicesImage = StorageUtils.getLanguage() === window.constants.LANGUAGE_CN? <div className={styles.vpn_features_image_CN}></div> : <div className={styles.vpn_features_image_EN}></div>;

        return (
            <div id="container" className={styles.container}>
                <Layout className={styles.content_container}>
                    <Header id="Header" className={styles.header}>
                        <div className={styles.app_info}  onClick={() =>this.scrollToAnchor('product_section')}>
                            <img className={styles.logo} src={require('../images/logo_home.png')}/>
                            <label className={styles.name}>SquirrelVPN</label>
                        </div>

                        <Menu mode="horizontal" theme="dark" className={styles.squirrel_menu}>
                            <Menu.Item key="home">
                                <a href="javascript:;" rel="noopener noreferrer"
                                   onClick={() => this.scrollToAnchor('product_section')}>
                                    {window.language.menus.home}
                                </a>
                            </Menu.Item>
                            <Menu.Item key="download">
                                <a href="javascript:;" rel="noopener noreferrer"
                                   onClick={() => this.scrollToAnchor('download_section')}>
                                    {window.language.menus.download}
                                </a>
                            </Menu.Item>
                            <Menu.Item key="buy">
                                <a href="javascript:;" rel="noopener noreferrer"
                                   onClick={() => this.scrollToAnchor('buy_section')}>
                                    {window.language.menus.buy}
                                </a>

                            </Menu.Item>
                            <Menu.Item key="services" className={styles.hidden_on_mobile}>
                                <a href="/blog">
                                    {window.language.menus.blog}
                                </a>
                            </Menu.Item>
                            <Menu.Item key="support">
                                <a href="javascript:;" rel="noopener noreferrer"
                                   onClick={() => this.scrollToAnchor('support_section')}>
                                    {window.language.menus.support}
                                </a>
                            </Menu.Item>
                        </Menu>

                        {
                            this.state.email || this.state.isLogin ?
                                <Popover className={styles.login_section} placement="bottomRight" content={(
                                    <div className={styles.profile_section}>
                                        <div className={styles.logo_email}>{StorageUtils.getEmail()}</div>
                                        <div className={styles.logo_list_line}></div>
                                        <div className={styles.logo_list_item} onClick={() => this.setState({showUserInfoModal: true})}>
                                            {window.language.account_info}
                                        </div>
                                        <div className={styles.logo_list_item} onClick={() => this.setState({showResetPasswordModal: true})}>
                                            {window.language.reset_password}
                                        </div>
                                        <div className={styles.logo_list_item} onClick={() => this.setState({showChangeEmailModal: true})}>
                                            {window.language.change_email}
                                        </div>
                                        <div className={styles.logo_list_item} onClick={() => this.props.history.push('/views/nodes/' + StorageUtils.getUserId())}>
                                            {window.language.node_list_text}
                                        </div>
                                        <div className={styles.logo_list_item} onClick={() => this.setState({showGiftCardModal: true})}>
                                            {window.language.gift_card_text}
                                        </div>
                                        {/*<div className={styles.logo_list_item} onClick={() => this.setState({showInvitationModal: true})}>
                                            {window.language.apply_invite_code_text}
                                        </div>*/}
                                        <div className={styles.logo_list_item} onClick={() => {
                                            StorageUtils.remove(window.constants.CURRENT_USER);
                                            this.setState({email: '', isLogin: false})
                                        }}>
                                            {window.language.logout_text}
                                        </div>
                                    </div>
                                )}>
                                    <Avatar className={styles.photo} icon="user" style={{backgroundColor: 'rgb(81, 85, 125)',cursor:'pointer'}}/>
                                </Popover>
                                :
                                <Button type="link" className={styles.login_section_link}
                                        onClick={() => this.setState({showLoginRegisterModal: true})}>
                                    {window.language.login_text}
                                </Button>
                        }

                        <div className={styles.language_switch_section}>
                            <Switch checkedChildren="中文" unCheckedChildren="EN"
                                    defaultChecked={StorageUtils.getLanguage() === window.constants.LANGUAGE_CN}
                                    className={styles.language_switch}
                                    onChange={(value) => {
                                        this.switchLanguage(value);
                                    }}/>
                        </div>

                    </Header>

                    <Content>
                    <div className={styles.website_title} id="product_section">
                        <h1>{window.language.product_section.title1}</h1>
                        <h1 className={styles.no_padding}>{window.language.product_section.title2}</h1>
                        <h3>{window.language.product_section.subtitle}</h3>
                        <button className={styles.btn_buy}
                                onClick={() => this.scrollToAnchor('buy_section')}>{window.language.product_section.btn_buy}
                        </button>
                        <button className={styles.btn_buy}
                                onClick={() => this.scrollToAnchor('download_section')}>{window.language.product_section.btn_download}
                        </button>
                        <div className={styles.app_demo_image}></div>
                    </div>

                    <div className={styles.technology_section}>
                        <h1>{window.language.technology_section.title}</h1>
                        <br/><br/>
                        <h3> {window.language.technology_section.text1} </h3>
                        <h3> {window.language.technology_section.text2} </h3>
                        <h3> {window.language.technology_section.text3} </h3>
                    </div>

                    <div className={styles.download_section} id="download_section">
                        <h1>{window.language.download_section.title}</h1>
                        <div className={styles.download_panel}>
                            <h2>{window.language.download_section.device}</h2>
                            <h4>Android</h4>
                            <button onClick={() => this.downloadAndroid()} className={styles.btn_download}>SquirrelVPN
                                for Android
                            </button>
                            {/*<p>
                                <a className={styles.link} onClick={() => this.setState({androidModalVisible: true})}>
                                {window.language.download_section.android_not_working}
                                </a>
                            </p>*/}
                            ​<p className={styles.light}></p>
                            <button onClick={() => this.openGooglePlay()} className={styles.btn_download}>
                                <img src={require('../images/google-play.png')}/>
                                Google Play
                            </button>
                            <div className={styles.qr_image}><img src={require('../images/qr-android.png')}/></div>
                                           <br/>
                            <h4>iOS</h4>
                            {/* <p>SquirrelVPN Pro (TestFlight)</p>
                            <button className={styles.btn_download} onClick={() => this.openTestFlight()}>
                                <img className={styles.hidden_on_desktop} src={require('../images/icon-pro.png')}/>
                                SquirrelVPN Pro
                            </button>
                            <p className={styles.light}> {window.language.download_section.pro_description}<br/>
                                {window.language.download_section.pro_description2}</p>
                            <div className={styles.qr_image}><img src={require('../images/qr-testflight.png')}/></div>
                            <br/> */}
                            {/* <br/> */}
                            <p>SquirrelVPN (App Store)</p>
                            <button className={styles.btn_download} onClick={() => this.showAppStoreModal()}>
                                <img className={styles.hidden_on_desktop} src={require('../images/icon-app-store.png')}/>
                                SquirrelVPN
                            </button>
                            <div className={styles.qr_image}><img src={require('../images/qr-app-store.png')}/></div>
                        </div>
                        <div className={styles.download_panel}>
                            <h2>{window.language.download_section.device2}</h2>
                            <p> {window.language.download_section.description1}<br/>
                                {window.language.download_section.description2}</p>

                            <h4>Windows</h4>

                            <button onClick={() => this.downloadWindows()} className={styles.btn_download}>SquirrelVPN
                                for Windows
                            </button>
                ​            <p className={styles.light}>
                                {window.language.download_section.support_win} <br/></p>
                            <p><a className={styles.link} target="_blank"
                            href="https://download.microsoft.com/download/7/5/0/750698D5-74F3-48B5-A2BE-8564F68890CC/Windows6.1-KB3004394-v2-x64.msu">
                                {window.language.download_section.win7_updater_reminder}</a>
                            </p>
                            <br/>
                            <button onClick={() => this.downloadMicrosoftStore()} className={styles.btn_download}>
                                <img src={require('../images/microsoft.png')}/>
                                Microsoft Store
                            </button>
                ​            <p className={styles.light}>
                                {window.language.download_section.support_win_store} <br/>
                            </p>

                            <h4>Mac</h4>
                            <button onClick={() => this.downloadMac()} className={styles.btn_download}>SquirrelVPN for
                                MacOS
                            </button>
                            ​<p className={styles.light}>{window.language.download_section.support_mac}<br/></p>

                            <h4>Linux</h4>
                            <button onClick={() => this.downloadLinux()} className={styles.btn_download}>SquirrelVPN
                                for Linux
                            </button>
                ​            <p className={styles.light}>
                               <a className={styles.link} target="_blank" onClick={() => this.showlinuxTooltipModal()}>
                               {window.language.download_section.tip_linux}</a>
                               <br/>
                               {window.language.download_section.support_linux}<br/>
                            </p>
                        </div>
                        <div className={styles.download_panel}>
                            <h2>{window.language.download_section.device3}</h2>
                            <p> {window.language.download_section.descriptionTV}<br/>
                                {window.language.download_section.descriptionTV2}</p>

                            <h4>Android TV</h4>
                            <button onClick={() => this.downloadAndroidTVapk()} className={styles.btn_download}>
                                Android TV apk
                            </button>
                            <p><a className={styles.link} href="/blog/2021/05/04/android-TV-tutorial/" target="_blank">
                                {window.language.download_section.tv_apk_blog}</a>
                            </p>
                            <p className={styles.light}></p>
                            {/* <button onClick={() => this.openGooglePlayAndroidTV()} className={styles.btn_download}>
                                <img src={require('../images/google-play.png')}/>
                                Google Play TV
                            </button> */}
                        </div>
                    </div>

                    <div className={styles.website_title} id="services_section">
                        <h1>{window.language.services_section.title}</h1>
                        {vpnServicesImage}
                    </div>

                    <div className={styles.buy_section} id="buy_section">
                      <h1>{window.language.buy_section.title}</h1>
                        {isChinese ? (
                          <img src={require('../images/6-months-CN.png')} className={styles.pkg_item}  style={{marginLeft: 0}}
                          onClick={() => this.openPaymentModal(window.products.half_year.key)}/>
                        ) : (
                          <img src={require('../images/6-months.png')} className={styles.pkg_item}  style={{marginLeft: 0}}
                                onClick={() => this.openPaymentModal(window.products.half_year.key)}/>
                        )}
                        {isChinese ? (
                          <img src={require('../images/1-year-CN.png')} className={styles.pkg_item_large}
                          onClick={() => this.openPaymentModal(window.products.year.key)}/>
                        ) : (
                          <img src={require('../images/1-year.png')} className={styles.pkg_item_large}
                                onClick={() => this.openPaymentModal(window.products.year.key)}/>
                        )}
                        {isChinese ? (
                          <img src={require('../images/1-month-CN.png')} className={styles.pkg_item}
                          onClick={() => this.openPaymentModal(window.products.month.key)}/>
                        ) : (
                          <img src={require('../images/1-month.png')} className={styles.pkg_item}
                                onClick={() => this.openPaymentModal(window.products.month.key)}/>
                        )}
                    </div>

                    <div className={styles.support_section} id="support_section">
                        <div className={styles.box_left}>
                            <h1>{window.language.support_section.title}</h1>
                            <div className={styles.support_details}>
                                <h2>{window.language.support_section.subtitle1}</h2>
                                ​{window.language.support_section.email} {window.constants.SUPPORT_EMAIL}
                            </div>
                            <div className={styles.support_details}>
                                <h2>{window.language.support_section.subtitle2}</h2>
                                {window.language.support_section.telegram1}
                                <a href="https://t.me/SqVPNGroup" target="_blank">SquirrelVPN Group(中文)</a>
                                <br/>
                                {window.language.support_section.telegram1}
                                <a href="https://t.me/SqVPNGroupEN" target="_blank">SquirrelVPN Group(EN)</a>
                                <br/>
                                {window.language.support_section.telegram2}
                                <a href="https://t.me/SqVPNChannel" target="_blank">SquirrelVPN Channel(中文）</a>
                                <br/>
                                {window.language.support_section.telegram2}
                                <a href="https://t.me/SqVPNChannelEN" target="_blank">SquirrelVPN Channel(EN)</a>
                                <br/>
                                {window.language.support_section.text}<a
                                href="https://github.com/squirrelvpn/download/blob/master/README.md"
                                target="_blank">Github</a>
                            </div>
                            <div className={styles.support_details}>
                                <h2>{window.language.support_section.subtitle3}</h2>
                                <a href="/blog/2021/02/03/user-manual-faq/" target="_blank">{window.language.support_section.faq}</a><br/>
                                {/*<a href="/blog/2021/12/08/iOS-register-apple-id-2022/" target="_blank">{window.language.support_section.tutorial2}</a><br/>
                                <a href="/blog/2021/07/02/how-to-buy-BTC/" target="_blank">{window.language.support_section.tutorial4}</a><br/>*/}
                            </div>
                        </div>
                        <div className={styles.box_right}>
                        </div>
                    </div>
                    </Content>

                    <Footer id="Footer" className={styles.footer}>
                        <p><a href="privacy-policy.html" target="_blank"> Privacy Policy</a></p>
                        <p><a href="terms-of-service.html" target="_blank">Terms Of Service</a></p>
                        <p>SquirrelVPN©{new Date().getFullYear()} All Rights Reserved</p>
                    </Footer>

                </Layout>

                <div>
                    <Modal
                        visible={this.state.androidModalVisible}
                        title={window.language.download_section.android_not_working}
                        onOk={() => this.setState({androidModalVisible: false})}
                        onCancel={() => this.setState({androidModalVisible: false})}
                        footer={[
                            <Button key="submit" type="primary"
                                    onClick={() => this.setState({androidModalVisible: false})}>
                                OK
                            </Button>
                        ]}
                    >
                        {StorageUtils.getLanguage() === window.constants.LANGUAGE_CN ?
                            <div>
                                <p>
                                    目前有少部分Android用户，成功连接之后无法上网，请暂时使用用户中心的
                                    <a onClick={() => this.props.history.push('/views/nodes/' + StorageUtils.getUserId())}>订阅节点</a>，
                                    Squirrel技术团队很快会修复该问题。
                                </p>
                                <p>推荐第三方节点客户端</p>
                                <p>
                                    <a href="https://www.squirrelhome.link/releases/SagerNet-0.8-armeabi-v7a.apk">SagerNet-0.8-armeabi-v7a.apk</a>
                                </p>
                            </div>
                            :
                            <div>
                                <p>
                                    Android is not working?, please use
                                    <a onClick={() => this.props.history.push('/views/nodes/' + StorageUtils.getUserId())}>
                                         &nbsp;Subscribe Nodes&nbsp;
                                    </a>
                                    in the user center, we will fix it as soon as possible.
                                </p>
                                <p>Recommend third-party VPN clients</p>
                                <p>
                                    <a href="https://www.squirrelhome.link/releases/SagerNet-0.8-armeabi-v7a.apk">SagerNet-0.8-armeabi-v7a.apk</a>
                                </p>
                            </div>
                        }
                    </Modal>
                </div>

                <div>
                  <Modal
                    visible={this.state.appStoreModalVisible}
                    /*title={window.language.download_section.app_store_modal_title}*/
                    onOk={this.handleAppStoreModalOk}
                    onCancel={this.handleAppStoreModalCancel}
                    footer={null}
                    /*footer={[
                        <Button key="submit" type="primary" onClick={this.handleAppStoreModalOk}>
                            {window.language.download_section.app_store_modal_ok}
                        </Button>
                    ]}*/
                  >
                      {StorageUtils.getLanguage() === window.constants.LANGUAGE_CN ?
                          <div>
                              {/*<p>
                                  目前SquirrelVPN临时下架，请先使用用户中心的
                                  <a onClick={() => this.props.history.push('/views/nodes/' + StorageUtils.getUserId())}>订阅节点</a>，
                                  已经从AppStore安装SquirrelVPN的千万别卸载了。
                              </p>*/}
                              <br/>
                              <p>社区VPN应用（推荐）：</p>
                              <p>
                                  <a href="https://apps.apple.com/us/app/ganan-net/id1625533060">Ganan Net</a>
                              </p>
                              <br/>
                              <p>社区VPN节点客户端：</p>
                              <p>
                                  <a href="https://apps.apple.com/us/app/shadowrocket/id932747118">Shadowrocket</a>
                              </p>
                              <p>
                                  <a href="https://apps.apple.com/us/app/spectre-vpn/id1508712998">Spectre VPN</a>
                              </p>
                          </div>
                          :
                          <div>
                              {/*<p>
                                  SquirrelVPN is temporally suspended in App Store, please use
                                  <a onClick={() => this.props.history.push('/views/nodes/' + StorageUtils.getUserId())}> Subscribe
                                      Nodes</a> in the user center,
                                  don't uninstall if you have already installed SquirrelVPN from the App Store.
                              </p>*/}
                              <br/>
                              <p>Community VPN Apps (Recommended):</p>
                              <p>
                                  <a href="https://apps.apple.com/us/app/ganan-net/id1625533060">Ganan Net</a>
                              </p>
                              <br/>
                              <p>Community VPN Clients:</p>
                              <p>
                                  <a href="https://apps.apple.com/us/app/shadowrocket/id932747118">Shadowrocket</a>
                              </p>
                              <p>
                                  <a href="https://apps.apple.com/us/app/spectre-vpn/id1508712998">Spectre VPN</a>
                              </p>
                          </div>
                      }

                     {/* <p>{window.language.support_section.subtiResourcetle3}</p>
                      <p>
                          <a className={styles.link} href="/blog/2021/01/28/iOS-switch-apple-id/"
                             target="_blank"> {window.language.download_section.apple_id}</a>
                      </p>
                      <p>
                          <a className={styles.link} href="/blog/2021/12/08/iOS-register-apple-id-2022/"
                             target="_blank"> {window.language.download_section.register_apple_id}</a>
                      </p>*/}
                  </Modal>
                </div>

                <div>
                  <Modal
                    visible={this.state.linuxTooltipModalVisible}
                    title={window.language.download_section.linux_tooltip_title}
                    onOk={this.handleLinuxModalOk}
                    onCancel={this.handleLinuxModalCancel}
                    footer={[
                       <Button key="submit" type="primary" onClick={this.handleLinuxModalOk}>
                        OK
                       </Button>
                    ]}
                  >
                    <p>{window.language.download_section.linux_tooltip_body}</p>
                  </Modal>
                </div>

                <LoginRegisterModal visible={this.state.showLoginRegisterModal}
                                    loginCallback={(userInfo) => {
                                        this.setState({showLoginRegisterModal: false, isLogin: true});
                                        this.loadUserInfo(userInfo.userId)
                                    }}
                                    onRegister={() => {
                                        this.props.history.push('/views/register')
                                    }}
                                    onResetPassword={() => {
                                        this.setState({showLoginRegisterModal: false, showResetPasswordModal: true});
                                    }}
                                    onRequestClose={() => this.setState({showLoginRegisterModal: false})}/>
                <InvitationModal visible={this.state.showInvitationModal}
                                    loginCallback={() => {
                                        this.setState({showInvitationModal: false, isLogin: true});
                                    }}
                                    onRequestClose={() => this.setState({showInvitationModal: false})}/>
                <GiftCardModal visible={this.state.showGiftCardModal}
                                    loginCallback={() => {
                                        this.setState({showGiftCardModal: false, isLogin: true});
                                    }}
                                    onRequestClose={() => this.setState({showGiftCardModal: false})}/>
                <ResetPasswordModal visible={this.state.showResetPasswordModal}
                                    onResetPassword={(userInfo) => {
                                        this.setState({showResetPasswordModal: false, isLogin: true, email: userInfo.email});
                                        this.loadUserInfo(userInfo.userId)
                                    }}
                                    onRequestClose={() => this.setState({showResetPasswordModal: false})}/>
                <ChangeEmailModal visible={this.state.showChangeEmailModal}
                                    onChangeEmail={(userInfo) => {
                                        this.setState({showChangeEmailModal: false, isLogin: true, email: userInfo.email});
                                        this.loadUserInfo(userInfo.userId)
                                    }}
                                    onRequestClose={() => this.setState({showChangeEmailModal: false})}/>
                <UserInfoModal visible={this.state.showUserInfoModal}
                                    userInfo={this.state.userInfo}
                                    onRequestClose={() => this.setState({showUserInfoModal: false})}/>
                <PaymentModal visible={this.state.showPaymentModal}
                              productDetail={this.state.selectedPackage}
                              loginCallback={() => {
                                  this.setState({
                                      showPaymentModal: false,
                                      showLoginRegisterModal: true
                                  });
                              }}
                              selectionCallback={(method) => {
                                  // close payment modal
                                  this.setState({showPaymentModal: false});

                                  // open target payment method modal
                                  if(method === 'credit'){
                                      this.setState({showCreditCardModal: true});
                                  } else if (method === 'coinpayment') {
                                      this.setState({showCryptoCoinModal: true});
                                  }
                              }}
                              onRequestClose={() => this.setState({showPaymentModal: false})}/>
                <CreditCardModal visible={this.state.showCreditCardModal}
                                 productDetail={this.state.selectedPackage}
                                 onRequestClose={() => this.setState({showCreditCardModal: false})}/>
                <CryptoCoinModal visible={this.state.showCryptoCoinModal}
                                 history={this.props.history}
                                 productDetail={this.state.selectedPackage}
                                 loginCallback={() => {
                                     this.setState({
                                         showCryptoCoinModal: false,
                                         showLoginRegisterModal: true
                                     });
                                 }}
                                 onRequestClose={() => this.setState({showCryptoCoinModal: false})}/>
                <BgAnimations />
            </div>

        );
    }


    openPaymentModal(selectedPackage){
        this.setState({
            selectedPackage: window.products[selectedPackage],
            showPaymentModal: true
        })
    }

}


